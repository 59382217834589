import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import "./SavedSearch.css";
import { homeTypeDetails, getListingStatus,formatPrice } from '../../common/GenericFunction';
import { editSearch } from '../../components/Api/ListingApi';
import ToastPopup from '../../components/popup/ToastPopup';

export default function EditSavedSearch(props) {
    // props declaration //
    const { toggleEditSearch, editsavedSearch, editDetails, reload } = props;
    

    //usesate declaration //
    const [messages, setMessages] = useState("")
    const [success, setSucess] = useState(false);
    // Define state to manage the input value
    const [searchName, setSearchName] = useState('');
    const [notifyMeData, setNotifyMeData] = useState("")
    const toggleSuccess = () => setSucess(p => !p);
    // useeffect declaration //
    useEffect(() => {
        setSearchName(editDetails.name); // Set initial value when editDetails changes
        // setNotifyMeData(editDetails.notify_me)
        setNotifyMeData(editDetails.notify_me)
    }, [editDetails]);

    //onchange for notify me select //
    const handleSelectChange = (e) => {
        // console.log("selectElement", e.target.value)
        let selectedValue = e.target.value
        setNotifyMeData(selectedValue); // Pass the selected value to the function
    }

    // Api call for edit saved search //
    const toggleEditSearches = async () => {
        // let userId = localStorage.getItem("userId")
        let id = editDetails._id
        let getParams = {
            name: searchName,
            city: editDetails.city,
            Listing_type: "For Sale",
            listing_status: editDetails.listing_status,
            beds: editDetails.bed,
            baths: editDetails.bath,
            price: editDetails?.price?.$numberDecimal && editDetails.price.$numberDecimal,
            sub_class: editDetails.sub_class,
            priceRangeMin: editDetails?.priceRangeMin?.$numberDecimal && editDetails.priceRangeMin.$numberDecimal,
            priceRangeMax: editDetails?.priceRangeMax?.$numberDecimal && editDetails.priceRangeMax.$numberDecimal,
            notify_me: notifyMeData,
            lot_size_min: editDetails?.lot_size_min?.$numberDecimal ? editDetails.lot_size_min.$numberDecimal : null,
            lot_size_max: editDetails?.lot_size_max?.$numberDecimal ? editDetails.lot_size_max.$numberDecimal : null,
            price_per_sqft_min: editDetails?.price_per_sqft_min?.$numberDecimal ? editDetails.price_per_sqft_min.$numberDecimal : null,
            price_per_sqft_max: editDetails?.price_per_sqft_max?.$numberDecimal ? editDetails.price_per_sqft_max.$numberDecimal : null,
            square_feet_min: editDetails?.square_feet_min?.$numberDecimal ? editDetails.square_feet_min.$numberDecimal : null,
            square_feet_max: editDetails?.square_feet_max?.$numberDecimal ? editDetails.square_feet_max.$numberDecimal : null,
            stories_min: editDetails?.stories_min ? editDetails.stories_min : null,
            stories_max: editDetails?.stories_max ? editDetails.stories_max : null,
            year_built_min: editDetails?.year_built_min ? editDetails.year_built_min : null,
            year_built_max: editDetails?.year_built_max ? editDetails.year_built_max : null
        }
        await editSearch(id, getParams).then((res) => {
            reload()
            setMessages(res.data.message)
            toggleSuccess()
            setTimeout(() => toggleSuccess(), 3000)

        }).catch((err) => {
            console.error(err)
        })
    }

    // Number format function //
    const nFormatter = (num, digits) => {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" }, // Modify "M" to " million"
            { value: 1e9, symbol: "B" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: " quadrillion" },
            { value: 1e18, symbol: " quintillion" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : '';
    };

    return (
        <div>
            <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
            <Modal show={editsavedSearch} onHide={toggleEditSearch} className="modelparent">
                <div className="PopUpBorderDiv">
                    <Modal.Header closeButton className="team-popup-margin1 teampopup-title savedSearchshare ">
                        <Modal.Title className='form-field-label form-label-team ' ><h4 className='savedSearchtitle'>Edit Saved Searches</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="team-popup-margin1 pb-0" >
                        <div>
                            <div className='position-relative savedSearchinput mb-3' >
                                <h5 className='savedSearchtitle1'>Name your search</h5>
                                <input
                                    type="search"
                                    className="form-control"
                                    value={searchName ? searchName : editDetails.city}
                                    placeholder="Search name"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    style={{ borderRadius: "0px" }}
                                    onChange={(e) => setSearchName(e.target.value)}
                                />
                                <div className='savedsearchClose position-absolute cursor-pointer'>
                                    <img src="/images/closeIconProfile.png" alt="close" style={{ height: "18px" }} />
                                </div>
                                <div style={{ height: "140px" }}>
                                    <p className='savedSearchdata mt-3'>
                                        For Sale: <span>
                                            {editDetails?.listing_status && editDetails.listing_status.map((item) => getListingStatus(item)).join(', ')}
                                        </span>,{' '}
                                        {editDetails?.priceRangeMin?.$numberDecimal && editDetails?.priceRangeMax?.$numberDecimal && `${editDetails.priceRangeMin?.$numberDecimal ? `$${nFormatter(parseFloat(editDetails.priceRangeMin.$numberDecimal), 2)} - ` : ''}$${nFormatter(parseFloat(editDetails.priceRangeMax.$numberDecimal), 2)}`}
                                        {editDetails?.beds && `, ${editDetails.beds} Beds`}
                                        {editDetails?.baths && `, ${editDetails.baths} Baths`}
                                        {editDetails?.sub_class && editDetails.sub_class.length > 0 && `, ${editDetails.sub_class.map((item, index, array) => index === 0 ? homeTypeDetails(item) : `, ${homeTypeDetails(item)}`).join('')}`}
                                        {editDetails?.lot_size_min?.$numberDecimal && editDetails?.lot_size_max?.$numberDecimal && `, Lot size: ${formatPrice(editDetails.lot_size_min.$numberDecimal)} - ${formatPrice(editDetails.lot_size_max.$numberDecimal)}`}
                                        {editDetails?.square_feet_min?.$numberDecimal && editDetails?.square_feet_max?.$numberDecimal && `, Sq.Ft: ${formatPrice(editDetails.square_feet_min.$numberDecimal)} - ${formatPrice(editDetails.square_feet_max.$numberDecimal)}`}
                                        {editDetails?.stories_min && editDetails?.stories_max && `, Stories: ${editDetails.stories_min} - ${editDetails.stories_max}`}
                                        {editDetails?.year_built_min && editDetails?.year_built_max && `, Year built: ${editDetails.year_built_min} - ${editDetails.year_built_max}`}
                                        {editDetails?.price_per_sqft_min?.$numberDecimal && editDetails?.price_per_sqft_max?.$numberDecimal && `, Price per square feet: ${formatPrice(editDetails.price_per_sqft_min.$numberDecimal)} - ${formatPrice(editDetails.price_per_sqft_max.$numberDecimal)}`}

                                    </p>
                                </div>
                                <div>
                                    <h5 className='savedSearchtitle1'>Notify me</h5>
                                    <select class="form-select" aria-label="Default select example" onChange={handleSelectChange} value={notifyMeData}>
                                        <option value="Instant">Instant</option>
                                        <option value="Daily">Daily</option>
                                        <option value="Never">Never</option>
                                    </select>
                                </div>
                            </div>
                            <div className='mb-3'>
                                <Button className="mb-3 mt-3 savedSearchbut" onClick={() => { toggleEditSearches(); toggleEditSearch() }}>Update Search</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}
